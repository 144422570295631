* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Helvetica, "Helvetica Neue", Arial, "Lucida Grande", sans-serif;
}

body {
  background-color: #fffcf7;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  p {
    color: #746b60;
  }

  a {
    text-decoration: none;
  }

  button {
    cursor: pointer;
    color: #141414;

    &:focus {
      outline: none;
    }
  }
}
