.cardBackGround {
  width: 100%;
  height: 100vh;
  background-color: rgb(255, 252, 247, 0.5);
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;

  .expandedCard {
    max-width: 1200px;
    height: 100vh;
    top: 0px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    background-color: #fffcf7;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 18px;

    .closeIcon {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    img {
      width: 70%;
      max-width: 400px;
      height: auto;
      margin-top: 50px;
      height: 30%;
    }

    .detailSection {
      height: -webkit-fill-available;
      width: 100%;
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      p {
        text-align: center;
      }

      h2 {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
        text-align: center;

        button {
          background: lightgreen;
          border: none;
          border-radius: 50%;
          height: 20px;
          font-size: 18px;
          width: 20px;
        }
      }

      .counter {
        width: 90%;
        border-bottom: 2px solid #90887e;
        display: flex;
        justify-content: space-between;

        p {
          color: #141414;
        }

        button {
          border: none;
          background-color: transparent;
          height: 30px;
          color: #746b60;
        }

        input {
          margin: 0 10px;
          width: 50px;
          font-size: 18px;
          border: none;
          background-color: transparent;
          text-align: center;
        }
      }

      .add {
        height: 50px;
        width: 90%;
        background-color: transparent;
        border: 2px solid black;
        font-weight: bolder;
        font-size: 16px;
      }

      span {
        color: blue;
        svg {
          color: red;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .cardBackGround {
    .expandedCard {
      flex-direction: row-reverse;
      box-shadow: 0px 1px 20px 0px #888888;
      top: 100px;
      height: 600px;

      img {
        margin: 0;
      }

      .detailSection {
        width: 400px;
        height: 500px;
      }
    }
  }
}

.fadeIn {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
