.navBarMenu {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: #fffcf7;
  top: 0;
  right: 0;
  z-index: 10;

  & > svg:first-of-type {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 32px;
  }

  img {
    border-radius: 50%;
    height: auto;
    max-width: 65px;
  }

  ul {
    width: 50%;
    margin: 50px auto;
    list-style: none;

    li {
      height: 50px;
      width: 100%;
      color: black;
    }

    & > li:first-of-type {
      margin-bottom: 30px;
      font-size: 24px;
      display: flex;
      align-items: center;
      column-gap: 10px;

      svg {
        font-size: 32px;
      }
    }
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-11-20 16:39:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.slideInRight {
  -webkit-animation: slideInRight 0.5s linear both;
  animation: slideInRight 0.5s linear both;
}

@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInRight {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.slideOutRight {
  -webkit-animation: slideOutRight 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: slideOutRight 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}
