nav {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 21px;
  background-color: #faf3ec;
  z-index: 10;
  top: 0;
  width: 100%;
  max-width: 1200px;
  transition: 0.6s ease-out;
  margin: 0 auto;

  img {
    height: 80px;
    width: 250px;
  }

  .navBarRightSide {
    display: flex;
    width: 40%;
    justify-content: space-evenly;

    .cartAndTotal {
      column-gap: 5px;
      display: flex;
      align-items: baseline;

      button {
        color: #141414;
        position: relative;
        border: none;
        height: 25px;
        width: 25px;
        background-color: transparent;
        font-size: 21px;

        span {
          position: absolute;
          top: -5px;
          left: -12px;
          text-align: center;
          height: 17px;
          width: 17px;
          padding: 2px;
          background-color: black;
          color: white;
          border-radius: 10px;
          border: none;
          font-size: 12px;
        }
      }

      .cartTotal {
        height: 25px;
        color: #141414;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  h1 {
    font-size: 31px;
    color: #ee4b2b;
    width: max-content;
  }
}

// @media only screen and (min-width: 700px) {
//   nav {
//     justify-content: space-evenly;

//     div {
//       width: fit-content;
//       column-gap: 30px;

//       .cartTotal {
//         display: flex;
//       }
//     }

//     h1 {
//       font-size: 42px;
//     }
//   }
// }

@media only screen and (min-width: 768px) {
  nav {
    .navBarCentre {
      display: grid;
      grid-template-columns: 1fr 1fr;
      flex-wrap: wrap;
      padding: 20px;

      a {
        text-align: center;
        margin: 10px;
        color: #141414;
        min-width: 100px;
        height: 20px;
        white-space: nowrap;
      }
    }

    .navBarRightSide {
      width: 250px;
      display: flex;
      justify-content: flex-end;
      column-gap: 10px;

      button {
        border: none;
        background-color: transparent;
        font-size: 21px;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  nav {
    .navBarCentre {
      display: unset;

      a {
        padding: 5px;
      }
    }

    .navBarRightSide {
      justify-content: space-evenly;
    }
  }
}
