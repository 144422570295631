$primary-orange: rgba(252, 176, 69, 1);
$primary-red: #ec1b23;

.settings {
  min-height: 100vh;

  .pageHeader {
    width: fit-content;
    margin: 10px auto;
    font-size: 38px;
    color: #746b60;
  }

  .menu {
    width: 100%;
    text-align: center;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    align-items: center;
    margin: 0 auto;

    button {
      width: 250px;
      height: 60px;
      background-color: transparent;
      border: 2px solid black;
      font-size: 21px;
      margin: 10px 0;
    }

    .viewOn {
      display: flex;
    }

    h3 {
      font-size: 24px;
      cursor: pointer;
      margin: 30px 0;
      border-bottom: 2px solid #90887e;
      width: 100%;
    }

    article {
      width: 100%;
      display: flex;
      justify-content: space-between;
      text-align: center;
      margin: 20px 0;
      flex-wrap: wrap;
    }

    form {
      height: fit-content;
      display: none;
      flex-direction: column;
      align-items: flex-start;
      border: 1px solid #90887e;
      width: 90%;
      margin: 0 auto;

      div {
        margin: 10px auto;
        width: 90%;
        text-align: left;

        input {
          display: block;
          width: 90%;
          height: 40px;
          font-size: 18px;
          border: none;
          border-bottom: 2px solid #90887e;
          background: transparent;
        }

        select {
          display: block;
          margin: 5px auto;
          height: 30px;
          width: 200px;
        }
      }

      input[type="submit"] {
        width: 100px;
        height: 40px;
        background-color: transparent;
        border: 2px solid black;
        font-size: 21px;
        margin: 10px auto;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .settings {
    .menu {
      padding: 10px;
      width: 100%;
    }
  }
}
