.productCard {
  width: 100%;
  height: 200px;
  max-width: 500px;
  border-bottom: 2px solid #90887e;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;
  transition: 0.6s ease-out;
  cursor: pointer;

  &:hover {
    box-shadow: 6px 6px 20px 0px #888888;

    h2 {
      text-decoration: underline;
    }
  }

  .productInfos,
  .imgContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 30%;
    height: 100%;
    padding: 0 10px;
  }

  .productInfos {
    width: 70%;
    align-items: flex-start;

    h2 {
      button {
        background: lightgreen;
        border: none;
        border-radius: 50%;
        height: 20px;
        font-size: 18px;
        width: 20px;
      }
    }
  }

  .imgContainer {
    p {
      text-decoration: underline;
      text-align: center;
    }
  }

  div {
    position: relative;
    img {
      width: 100%;
      height: 100px;
    }

    span {
      position: absolute;
      top: -8px;
      right: -8px;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      color: white;
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.fadeIn {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
