.contactPage {
  h2 {
    margin: 20px;
    text-align: center;
    color: #f19c47;
  }

  p {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 30px;
    text-align: center;
    color: #f19c47;
  }

  .socials {
    display: flex;
    justify-content: center;
    width: 100%;

    a {
      color: unset;
      font-size: 28px;
      margin: 20px;
    }
  }

  img {
    width: 90%;
    height: auto;
    max-width: 400px;
    max-height: 400px;
    margin: 0 auto;
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .contactPage {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-evenly;

    img {
      margin: unset;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .contactPage {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-evenly;

    img {
      margin: unset;
      max-width: 600px;
      max-height: 600px;
    }
  }
}
