.productLargeScreen {
  pointer-events: none;
}

.productsContainer {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  h3 {
    margin: 50px;
    text-align: center;
    color: #746b60;
    border: 2px solid #746b60;
    padding: 20px;
    border-radius: 10px;
  }
}

.filters {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 20px 0;
  background-color: #fffcf7;
  position: sticky;
  top: 0;
  z-index: 1;

  button {
    border: none;
    background-color: transparent;
    font-size: 21px;
    margin: 0 20px;
    color: #90887e;
    transition: 0.6s ease-out;

    &:hover {
      color: black;
    }
  }
}

@media only screen and (min-width: 375px) {
  .filters {
    button {
      font-size: 28px;
    }
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.slideInLeft {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
