.cart {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 12;
  background-color: #fffcf7;

  .productsContainer {
    margin: 0 auto 180px;

    h3 {
      width: fit-content;
      margin: 20px;
      color: #746b60;
      font-size: 16px;
    }
  }

  .cartTopSection {
    height: 75px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 24px;
    border-bottom: 2px solid #90887e;
    font-weight: bold;
    color: #141414;
    cursor: pointer;

    svg {
      margin: 0 20px;
    }
  }

  .checkOut {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 450px;
    height: 150px;
    border-top: 2px solid #90887e;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    background-color: #fffcf7;

    div {
      margin: 0 auto;
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 21px;

      p {
        width: -webkit-fill-available;
      }

      span {
        font-weight: bold;
        display: flex;
        width: fit-content;
        font-size: 24px;

        input {
          font-size: 21px;
          border: none;
          background-color: transparent;
          width: 50px;
          font-weight: bold;
        }
      }
    }

    p {
      width: 90%;
      margin: 0 auto;
    }

    button {
      margin: 0 auto;
      height: 50px;
      width: 200px;
      background-color: transparent;
      font-weight: bold;
      font-size: 21px;
      border: 2px solid black;
    }
  }
}

@media only screen and (min-width: 768px) {
  .cart {
    width: 450px;
    box-shadow: 0px 1px 20px 0px #888888;
    position: fixed;
    display: none;
    overflow: auto;
  }
}
