.contactForm {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  div {
    width: 300px;
    margin: 20px auto;

    label {
      display: block;
      text-align: left;
      margin-bottom: 5px;
    }

    input,
    textarea {
      width: 300px;
      height: 40px;
      border: none;
      border-bottom: 2px solid black;
      background-color: transparent;

      &:focus {
        border-bottom: 3px solid black;
        outline: none;
      }

      &:hover {
        border-bottom: 3px solid black;
      }
    }

    textarea {
      height: 100px;
      resize: none;
    }
  }

  button {
    height: 50px;
    width: 300px;
    display: block;
    border: 2px solid black;
    background-color: transparent;
    margin: 20px auto;
    font-size: 21px;
  }
}
