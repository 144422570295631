.pageHeader {
  width: fit-content;
  margin: 10px auto;
  font-size: 38px;
  color: #746b60;
}

@media only screen and (min-width: 762px) {
  .pageHeader {
    font-size: 42px;
  }
}

@media only screen and (min-width: 1024px) {
  .dashBoard {
    max-width: 1200px;
    margin: 0 auto;
  }
}
