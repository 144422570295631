.cartProduct {
  margin: 10px auto;
  width: 100%;
  height: 150px;
  max-width: 400px;
  border-bottom: 2px solid #90887e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  p {
    height: 30px;
    border-bottom: 2px solid #90887e;
    width: fit-content;
  }

  span {
    text-align: center;
    font-weight: bold;
  }

  img {
    height: 100px;
    width: 100px;
  }

  .cartSides {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .quantity {
    margin-left: 10px;
    button {
      border: none;
      background-color: transparent;
      height: 30px;
      color: #746b60;
    }

    input {
      width: 50px;
      font-size: 18px;
      border: none;
      background-color: transparent;
      text-align: center;
    }
  }
}
