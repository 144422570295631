.homePage {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;

  .heroImage {
    width: 100%;
    position: relative;

    & > img {
      width: 100%;
      height: 50vh;
      object-fit: cover;
    }

    nav {
      background-color: rgba($color: #ffffff, $alpha: 0.4);
      position: absolute;
      top: 0px;
      width: 100%;
      max-width: unset;
    }
  }

  p {
    width: 90%;
    text-align: center;
    font-size: 18px;
    max-width: 800px;
  }

  .homepageButton {
    width: 250px;
    height: 50px;
    background-color: transparent;
    border: 2px solid black;
    font-size: 21px;
  }
}

.homePageBottomSection {
  min-height: 200vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  background-color: #faf3ec;

  img {
    width: 80%;
    height: 50vh;
    object-fit: cover;
  }

  h2 {
    font-size: 28px;
    text-align: center;
  }

  p {
    width: 90%;
    text-align: center;
    font-size: 18px;
    max-width: 800px;
  }
}
